<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('examine') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="(reviewed = true),currUser = multipleSelectionPres[0]"
            v-if="
              multipleSelectionPres.length == 1
            "
            >审核
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                currUser = scope.row;
                getInfo(scope.row.doctors_id);
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              active-text="启用"
              class="switch"
              inactive-text="禁用"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#c8c8c8"
              :active-value="1"
              :inactive-value="2"
              @change="institutionalStatus(scope.row.sign_id, scope.row.status)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'image' &&
              column.visible === true &&
              column.column_label == '医师头像'
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_pic"></el-avatar>
          </template>
        </el-table-column> -->
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-input
                v-if="'name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.keywords"
                clearable
                @change="handleSearch(column.column_prop, searchParams.name)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <el-dialog
          title="代理审核"
          :append-to-body="true"
          :visible.sync="reviewed"
        >
          <div class="edit-dialog">
            <div class="flex">
              <div
                class="flex"
                style="height: 30px;margin-left: 10px;font-weight: 400;"
              >
                <span>通过</span>
                <div
                  :class="passReview == '2' ? 'pass-review' : ''"
                  class="review cursor"
                  @click="passReview = '2'"
                ></div>
              </div>
              <div
                class="flex"
                style="height: 30px;margin-left: 30px;font-weight: 400;"
              >
                <span>拒绝</span>
                <div
                  :class="passReview == '3' ? 'pass-review' : ''"
                  class="review cursor"
                  @click="passReview = '3'"
                ></div>
              </div>
            </div>
            <div v-if="passReview == '3'">
              <span
                style="font-size: 14px;font-weight: bold;margin-left: 10px;color: #606266;"
                >拒绝原因</span
              >
              <el-input
                :disabled="passReview == '2'"
                type="textarea"
                v-model="denialReason"
                style="margin-top: 10px;"
              ></el-input>
            </div>
            <div v-if="passReview == '2'">
              <span
                style="font-size: 14px;font-weight: bold;margin-left: 10px;color: #606266;"
                >其他补贴费率</span
              >
              <el-input
                v-model="pres_rate"
                style="margin-left: 15px;width: 70%;"
              ></el-input>
              %
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="reviewed = false" class="btn-padding"
              >取 消</el-button
            >
            <el-button
              type="primary"
              @click="institutionReview"
              class="btn-padding"
              >审核</el-button
            >
          </div>
        </el-dialog>
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
    >
    </editTableHead>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { delOrgan } from "@/api/audit/doctor";
import { getProxyExamineList, postExamineProxy } from "@/api/audit/proxyAudit";
("@/api/audit/doctor2.0.js");
import { mapState } from "vuex";
export default {
  name: "proxyAudit",
 async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  data() {
    return {
      table_type:'proxyAudit',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name:'name',
          isSearch: false,
        }
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      pres_rate: 0,
      reviewed: false,
      passReview: "2",
      denialReason: "",

      dialogTitle: "",
      dialogType: "",
      datetimerange: [],
      currUser: {},
      searchParams: {},
      total: 0,
      listLoading: false,
      fullscreenLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      options: [],
      ruleForm: {
        examine: "",
      },

      form: {},
    };
  },
  computed: {
    getSuperviseName() {
      return (status) => {
        let curSupervise = this.superviseOptions.find((v) => v.value == status);
        return curSupervise ? curSupervise.label : "";
      };
    },
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  components: {
    Pagination,
    editTableHead,
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "proxyAudit",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.proxyAudit;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    institutionReview() {
      console.log(this.passReview);
      this.listLoading = true;
      // if (this.passReview == '3' && !this.denialReason) {
      // 	this.$message({
      // 		message: '请填写拒绝原因',
      // 		type: 'error'
      // 	})
      // 	this.fullscreenLoading = false
      // 	return
      // }
      if (this.passReview == "3") {
        this.denialReason = "";
      }
      let params = {
        status: this.passReview,
        reject_reason: this.denialReason,
        pres_rate: this.pres_rate,
      };
      postExamineProxy(params, this.currUser.id).then((res) => {
        if (res.code == 200) {
          if (this.passReview == "2") {
            this.$message({
              message: "审核通过",
              type: "success",
            });
          } else {
            this.$message({
              message: "审核已拒绝",
              type: "success",
            });
          }
          this.getList();
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        this.reviewed = false;
        this.listLoading = false;
      });
    },

    delItem(row) {
      this.$confirm("确认删除该机构?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delOrgan(row.sign_id).then((res) => {
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    getList() {
      this.listLoading = true;
      this.fullscreenLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.limit = this.listQuery.limit;

      getProxyExamineList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          this.$message.error(error.message);
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },

    dateChange(val) {
      if (val && val.length) {
        this.searchParams.created_at = JSON.stringify(val);
      } else {
        this.searchParams.created_at = [];
      }
      this.getList();
    },
  },
  filters: {
    controllshow(str, num) {
      //如果当前字符串小于nunm，返回原值
      if (str) {
        if (str.length <= num) {
          return str;
        }
        if (str.length > num) {
          return str.substr(0, num - 1) + "...";
        }
      } else {
        return str;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.edit-dialog {
  min-height: 20vh;
  max-height: 40vh;
  overflow: auto;

  .ipt {
    width: 80%;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 80%;
  }
}

.pass-review {
  width: 15px;
  height: 15px;
  background-color: #2362fb;
  border: 1px solid #dfe4ed;
  margin-left: 8px;
}

.flex {
  display: flex;
  align-items: center;
}

.cursor:hover {
  cursor: pointer;
}

.review {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #dfe4ed;
  margin-left: 8px;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
